<template>
  <button
    class="search-control ui-btn"
    :class="{
      'search-control--fade': getShouldFade
    }"
    @click.stop="emitClick"
    aria-label="Show the search input"
  >
    <Cross v-if="active" class="search-control__close-icon" />
    <Spyglass v-else class="search-control__search-icon" />
  </button>
</template>

<script>
import Cross from "@/assets/cross.svg"
import Spyglass from "./../../assets/search.svg"
import { mapState, mapGetters } from "vuex"

export default {
  props: {
    active: Boolean
  },

  components: {
    Cross,
    Spyglass
  },

  methods: {
    emitClick(e) {
      // console.log(this.active)
      this.$emit("click", e)
    }
  },

  computed: {
    ...mapState("ui", ["navActiveElement"]),
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),

    getShouldFade() {
      return (
        this.GET_IS_BELOW_BREAKPOINT("md") &&
        this.navActiveElement &&
        this.navActiveElement !== "search"
      )
    }
  }
}
</script>

<style lang="scss">
.search-control {
  cursor: pointer;
  position: relative;
  margin: 0 -8px;
  padding: 0 8px;
  pointer-events: all;
  transition: opacity 0.3s linear;

  &--fade {
    opacity: 0.5;
  }

  &__search-icon,
  &__close-icon {
    mix-blend-mode: difference;
    height: 22px;

    path {
      vector-effect: non-scaling-stroke;
    }

    @include media-breakpoint-up(xl) {
      height: 26px;
    }
  }

  &__search-icon {
    path {
      stroke: $bleach-white;
      stroke-width: 2px;
    }
  }

  &__close-icon {
    transform: scale(0.9);
    transform-origin: center;

    path {
      stroke: getcolour(bleach_white);
      stroke-width: 2px;
      fill: none;
    }
  }
}
</style>
